<template>
  <v-dialog
    :value="show"
    persistent
    max-width="1000"
    light
    scrollable
    content-class="no-print"
  >
    <v-card light>
      <v-card-title class="subtitle-2 align-center flex-column">
        - Shopee Order -
        <v-divider class="fill-width mt-4" />
        <v-row v-if="!stepConfirm" no-gutters align="center" class="d-flex fill-width pt-4">
          <v-col cols="3" class="col-sm-1 pr-0 pr-sm-2 mb-2 mb-sm-0">
            <div class="d-flex align-center c-pointer pr-2" style="user-select: none;" @click.prevent="SelectAll(dataSelected.length === shopee.data.length)">
              <v-icon v-if="dataSelected.length && dataSelected.length === shopee.data.length" color="primary" class="mr-1">
                mdi-checkbox-multiple-marked
              </v-icon>
              <v-icon v-else color="grey" class="mr-1">
                mdi-checkbox-multiple-blank-outline
              </v-icon> All
            </div>
          </v-col>
          <v-col cols="9" class="col-sm-3 pl-0 pl-sm-2 pr-0 pr-sm-2 mb-2 mb-sm-0">
            <v-select
              v-model="shopSelected"
              :disabled="shopee.loading"
              label="Select Shop"
              :items="shopeeList.data"
              :item-text="(v) => {
                return v ? v.data.shop_name : 'No name'
              }"
              outlined
              dense
              hide-details
              return-object
              append-icon="mdi-plus"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
              @click:append="OpenAuthorizeLink()"
            />
          </v-col>
          <v-col cols="12" class="col-sm-3 pl-0 pl-sm-2 pr-0 pr-sm-2 mb-2 mb-sm-0">
            <v-menu
              ref="menu"
              v-model="menu"
              :return-value.sync="dates"
              left
              offset-y
              :close-on-content-click="false"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateRangeText"
                  :disabled="shopee.loading"
                  label="Date Ranges"
                  append-icon="mdi-calendar-outline"
                  readonly
                  outlined
                  dense
                  hide-details
                  clearable
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="accent"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dates = []"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                :disabled="shopee.loading"
                no-title
                light
                class="force-light-font"
                range
              >
                <v-spacer />
                <v-btn
                  text
                  class="text-capitalize"
                  small
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  class="text-capitalize"
                  small
                  @click="$refs.menu.save(dates)"
                >
                  Ok
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" class="col-sm-2 pl-0 pl-sm-2 pr-0 pr-sm-2 mb-2 mb-sm-0">
            <v-select
              v-model="orderStatus"
              :disabled="shopee.loading"
              label="Order Status"
              :items="['ALL_STATUS', 'READY_TO_SHIP', 'SHIPPED', 'COMPLETED']"
              outlined
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
            />
          </v-col>
          <v-col cols="12" class="col-sm-3 pl-0 pl-sm-2 mb-2 mb-sm-0">
            <v-text-field
              v-model.lazy="orderSn"
              :disabled="shopee.loading"
              label="Order SN"
              outlined
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider v-if="!stepConfirm" />
      <!-- <div class="pa-2 text-center caption font-weight-bold orange lighten-4 c-pointer">
        {{ dataSelected.length }} ORDER SELECTED
      </div> -->
      <v-card-text class="pt-1 pb-1 px-6 text-center grey lighten-4" style="height: 75vh;overflow: auto;">
        <div v-if="shopee.loading" class="pt-4">
          Please wait ...
        </div>
        <div v-else-if="!shopee.error && !shopee.authLink">
          <div v-if="!shopee.data.length || transactionsList.length === 0" class="pt-4">
            No data
          </div>
          <div v-else-if="stepConfirm" class="py-2">
            <v-row v-if="nextTickDataSelected" class="flex-stretch">
              <v-col v-for="(so, iSo) in dataSelected" :key="'shopee-order-selected-' + iSo" cols="12" class="col-sm-4">
                <v-card :ripple="false" class="px-3 pt-2 rounded-lg fill-height d-flex flex-column justify-space-between">
                  <div class="fill-width">
                    <div class="d-flex fill-width align-center justify-space-between">
                      <div class="d-flex flex-column align-start text-left fill-width text-truncate caption-small" style="line-height: 140%;">
                        <span class="font-weight-bold">{{ so.order_sn }}</span>
                        <div class="orange--text text--darken-2 text-uppercase" style="line-height: 140%;">
                          {{ so.data[0].buyer_username }} (#{{ so.data[0].buyer_user_id }})
                        </div>
                      </div>
                      <div class="text-right" style="min-width: 110px;">
                        <div class="caption-small" style="line-height: 140%;">
                          <div class="" style="line-height: 140%;">
                            {{ so.order_status }}
                          </div>
                          {{ $price(so.data[0].total_amount) }}
                        </div>
                      </div>
                    </div>
                    <v-divider class="mt-2" />
                    <div class="pt-2 rounded-lg text-left">
                      <div class="caption-small text-uppercase" style="line-height: 140%;">
                        <strong><em>{{ so.data[0].recipient_address.name }}</em></strong>, {{ so.data[0].recipient_address.phone }}
                        <div>
                          {{ so.data[0].recipient_address.full_address }} }}
                          <!-- {{ so.data[0].recipient_address.full_address }}, {{ so.data[0].recipient_address.district || '-' }}, {{ so.data[0].recipient_address.city || '-' }} - {{ so.data[0].recipient_address.state || '-' }}, {{ so.data[0].recipient_address.zipcode || '-' }} -->
                        </div>
                        <div class="py-1">
                          <SearchCustomer
                            v-model.number="dataSelected[iSo].buyer"
                            :mpl="true"
                            :outlined="true"
                            :buyer-data="{ name: so.data[0].recipient_address.name, phone: $reformatPhone(so.data[0].recipient_address.phone) }"
                            :kecamatan="so.data[0].recipient_address.district"
                            :kota="so.data[0].recipient_address.city"
                            :kodepos="parseInt(so.data[0].recipient_address.zipcode) || 0"
                            :fulladdress="so.data[0].recipient_address.full_address"
                            @updated-customer="dataSelected[iSo].customer = $event"
                          />
                        </div>
                        <v-divider class="my-1" />
                        <div class="caption-small" style="line-height: 140%;">
                          <strong><em>{{ so.data[0].shipping_carrier || so.data[0].checkout_shipping_carrier }} {{ so.data[0].cod ? '[COD]' : '' }}</em></strong><br>
                          AWB : <strong>{{ so.shipping_detail.tracking_number || '-' }}</strong><span v-if="so.escrow_detail">, Fee: {{ $price(so.escrow_detail.order_income.actual_shipping_fee) }}, Customer Fee: {{ $price(so.escrow_detail.order_income.buyer_paid_shipping_fee) }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="caption-small text-left" style="line-height: 140%;">
                      Customer notes : {{ so.data[0].message_to_seller || '-' }}
                    </div>
                    <v-divider class="mt-2" />
                    <v-row v-if="so.escrow_detail" no-gutters class="pt-2">
                      <v-col v-for="(oi, iOi) in so.escrow_detail.order_income.items" :key="'shopee-order-item-' + iSo + '-' + iOi" cols="12"  :class="[IsProductSelected(oi.sku_crm) ? '' : 'red lighten-4']" class="px-2 py-1 mb-2">
                        <div class="caption-small d-flex fill-width justify-space-between align-center text-left" style="line-height: 140%!important;">
                          ({{ oi.quantity_purchased }}x) {{ oi.item_name }}
                          <strong>
                            {{ oi.model_name }}
                          </strong>
                        </div>
                        <div class="d-flex fill-width align-center">
                          <div class="text-left d-flex flex-column fill-width" style="line-height: 140%!important;">
                            <div class="caption-small text-uppercase" style="line-height: 140%!important;">
                              SKU: {{ oi.item_sku }} {{ oi.model_sku ? ('=> ' + oi.model_sku) : '' }}
                            </div>
                          </div>
                          <v-spacer />
                          <div class="d-flex flex-column align-end justify-end text-right primary--text" style="min-width: 100px;">
                            <div v-if="parseInt(oi.original_price) === parseInt(oi.discounted_price)" class="caption-small font-weight-bold" style="line-height: 140%!important;">
                              {{ $price(oi.original_price) }}
                            </div>
                            <div v-else class="text-right">
                              <div class="caption-small red--text text-decoration-line-through" style="line-height: 140%!important;">
                                {{ $price(oi.original_price) }}
                              </div>
                              <div class="caption-small font-weight-bold" style="line-height: 140%!important;">
                                {{ $price(oi.discounted_price) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="dataSelected[iSo].escrow_detail" class="py-1">
                          <v-autocomplete
                            v-model="dataSelected[iSo].escrow_detail.order_income.items[iOi].sku_crm"
                            label="Linked Crm Product"
                            :rules="[
                              (value) => IsProductSelected(value) || 'No product selected!'
                            ]"
                            :items="dataProduct"
                            :item-text="(v) => {
                              return ((v.name || '#') + ' (' + (v.sku || '#') + ')').toUpperCase() + ' - ' + v.category_name
                            }"
                            item-value="sku"
                            outlined
                            hide-details="auto"
                            dense
                            class="rounded"
                            @change="waitNextTickDataSelected()"
                          >
                            <template v-slot:item="data">
                              <div class="px-2 py-1 caption" style="line-height: 140%!important;">
                                <div class="caption-small font-weight-bold" style="line-height: 140%!important;">
                                  {{ data.item.sku || '#' }} - {{ data.item.category_name || '#' }}
                                </div>
                                {{ data.item.name }}
                              </div>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="fill-width pb-2">
                    <v-divider class="my-2" />
                    <div v-if="so.escrow_detail" class="caption-small d-flex flex-column fill-width justify-center align-end" style="line-height: 140%!important;">
                      <span>
                        Subtotal : {{ $price(so.escrow_detail.order_income.original_price) }}
                      </span>
                      <span class="red--text">
                        Discounted : -{{ $price(so.escrow_detail.order_income.seller_discount) }}
                      </span>
                      <span>
                        Total : {{ $price(so.escrow_detail.order_income.original_cost_of_goods_sold) }}
                      </span>
                      <span>
                        Customer Shipping Cost : {{ $price(so.escrow_detail.order_income.buyer_paid_shipping_fee) }}
                      </span>
                      <v-divider class="fill-width my-1" />
                      <strong class="pt-1">
                        Grand Total (CRM) : {{ $price(so.escrow_detail.order_income.original_cost_of_goods_sold + so.escrow_detail.order_income.buyer_paid_shipping_fee) }}
                      </strong>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div v-else class="py-2">
            <span v-for="(so, iSo) in shopee.data" :key="'shopee-order-' + iSo" :ripple="false" :class="[isDataSelected(so.order_sn) ? 'selected' : '']" class="shopee-data-list p-3 py-2 rounded-lg mb-3" @click.prevent="dataSelect(so)">
              <v-card v-if="transactionsList.filter((transaction) => transaction?.mpl_sn === so.order_sn).length === 0 && orderStatus === 'READY_TO_SHIP'" class="pl-3 pr-3 py-3">
              <!-- <v-card class="p-3"> -->
                <span>
                  <div class="d-flex fill-width align-center justify-space-between">
                    <div class="d-flex align-center text-left">
                      <div class="pr-2">
                        <v-icon v-if="isDataSelected(so.order_sn)" color="primary">
                          mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="grey">
                          mdi-check-circle-outline
                        </v-icon>
                      </div>
                      <div>
                        <span class="caption font-weight-bold" style="line-height: 140%;">ORDER SN : {{ so.order_sn }}</span>
                        <div v-if="so.escrow_detail" class="caption-small orange--text text--darken-2 text-uppercase" style="line-height: 140%;">
                          SHOPEE BUYER : {{ so.escrow_detail.buyer_user_name || '-' }}
                        </div>
                      </div>
                    </div>
                    <div class="text-right">
                      <div v-if="so.escrow_detail" class="caption">
                        <div class="caption-small">
                          {{ so.order_status }}
                        </div>
                        {{ $price(so.escrow_detail.order_income.buyer_total_amount) }}
                      </div>
                    </div>
                  </div>
                  <v-divider class="mt-2" />
                  <div class="pt-2 rounded-lg text-left">
                    <div class="caption-small text-uppercase" style="line-height: 140%;">
                      SHIP TO : {{ so.data[0].recipient_address.name }}, {{ so.data[0].recipient_address.phone }}
                      <div>
                        {{ so.data[0].recipient_address.full_address }}
                        <!-- {{ so.data[0].recipient_address.full_address }}, {{ so.data[0].recipient_address.district || '-' }}, {{ so.data[0].recipient_address.city || '-' }} - {{ so.data[0].recipient_address.state || '-' }}, {{ so.data[0].recipient_address.zipcode || '-' }} -->
                      </div>
                      <div class="caption-small" style="line-height: 140%;">
                        {{ so.data[0].shipping_carrier || so.data[0].checkout_shipping_carrier }}, AWB: <strong>{{ so.shipping_detail.tracking_number || '-' }}</strong><span v-if="so.escrow_detail">, Fee: {{ $price(so.escrow_detail.order_income.actual_shipping_fee) }}, Customer Fee: {{ $price(so.escrow_detail.order_income.buyer_paid_shipping_fee) }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="caption-small text-left" style="line-height: 140%;">
                    Customer notes : {{ so.data[0].message_to_seller || '-' }}
                  </div>
                  <v-divider class="mt-2" />
                  <v-row v-if="so.escrow_detail" no-gutters class="pt-2">
                    <v-col v-for="(oi, iOi) in so.escrow_detail.order_income.items" :key="'shopee-order-item-' + iSo + '-' + iOi" cols="12" class="col-sm-6 px-2 py-1 rounded" style="background-color: rgba(0, 0, 0, .04);">
                      <div class="d-flex fill-width align-center">
                        <div class="text-left d-flex flex-column fill-width" style="line-height: 140%!important;">
                          <div class="caption-small text-uppercase" style="line-height: 140%!important;">
                            SKU MAIN : {{ oi.item_sku }} {{ oi.model_sku ? (', SKU ITEM : ' + oi.model_sku) : '' }}
                          </div>
                          <div class="caption d-flex fill-width justify-space-between align-center" style="line-height: 140%!important;">
                            ({{ oi.quantity_purchased }}x) {{ oi.item_name }}
                            <strong>
                              {{ oi.model_name }}
                            </strong>
                          </div>
                        </div>
                        <v-spacer />
                        <div class="d-flex align-center justify-end pl-1 pr-2 py-1 text-right primary--text" style="min-width: 100px;">
                          <div v-if="parseInt(oi.original_price) === parseInt(oi.discounted_price)" class="caption-small font-weight-bold">
                            {{ $price(oi.original_price) }}
                          </div>
                          <div v-else class="text-right">
                            <div class="caption-small red--text text-decoration-line-through">
                              {{ $price(oi.original_price) }}
                            </div>
                            <div class="caption-small font-weight-bold">
                              {{ $price(oi.discounted_price) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="my-2" />
                  <div v-if="so.escrow_detail" class="caption-small d-flex flex-column fill-width justify-center align-end" style="line-height: 140%!important;">
                    <span>
                      Subtotal : {{ $price(so.escrow_detail.order_income.original_price) }}
                    </span>
                    <span class="red--text">
                      Discounted : -{{ $price(so.escrow_detail.order_income.seller_discount) }}
                    </span>
                    <span>
                      Total : {{ $price(so.escrow_detail.order_income.original_cost_of_goods_sold) }}
                    </span>
                    <span>
                      Customer Shipping Cost : {{ $price(so.escrow_detail.order_income.buyer_paid_shipping_fee) }}
                    </span>
                    <v-divider class="fill-width my-1" />
                    <strong class="pt-1">
                      Grand Total : {{ $price(so.escrow_detail.order_income.original_cost_of_goods_sold + so.escrow_detail.order_income.buyer_paid_shipping_fee) }}
                    </strong>
                  </div>
                </span>
              </v-card>
              <v-card v-else-if="orderStatus !== 'READY_TO_SHIP'" class="pl-3 pr-3 py-3">
              <!-- <v-card class="p-3"> -->
                <span>
                  <div class="d-flex fill-width align-center justify-space-between">
                    <div class="d-flex align-center text-left">
                      <div class="pr-2">
                        <v-icon v-if="isDataSelected(so.order_sn)" color="primary">
                          mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="grey">
                          mdi-check-circle-outline
                        </v-icon>
                      </div>
                      <div>
                        <span class="caption font-weight-bold" style="line-height: 140%;">ORDER SN : {{ so.order_sn }}</span>
                        <div v-if="so.escrow_detail" class="caption-small orange--text text--darken-2 text-uppercase" style="line-height: 140%;">
                          SHOPEE BUYER : {{ so.escrow_detail.buyer_user_name || '-' }}
                        </div>
                      </div>
                    </div>
                    <div class="text-right">
                      <div v-if="so.escrow_detail" class="caption">
                        <div class="caption-small">
                          {{ so.order_status }}
                        </div>
                        {{ $price(so.escrow_detail.order_income.buyer_total_amount) }}
                      </div>
                    </div>
                  </div>
                  <v-divider class="mt-2" />
                  <div class="pt-2 rounded-lg text-left">
                    <div class="caption-small text-uppercase" style="line-height: 140%;">
                      SHIP TO : {{ so.data[0].recipient_address.name }}, {{ so.data[0].recipient_address.phone }}
                      <div>
                        {{ so.data[0].recipient_address.full_address }}
                        <!-- {{ so.data[0].recipient_address.full_address }}, {{ so.data[0].recipient_address.district || '-' }}, {{ so.data[0].recipient_address.city || '-' }} - {{ so.data[0].recipient_address.state || '-' }}, {{ so.data[0].recipient_address.zipcode || '-' }} -->
                      </div>
                      <div class="caption-small" style="line-height: 140%;">
                        {{ so.data[0].shipping_carrier || so.data[0].checkout_shipping_carrier }}, AWB: <strong>{{ so.shipping_detail.tracking_number || '-' }}</strong><span v-if="so.escrow_detail">, Fee: {{ $price(so.escrow_detail.order_income.actual_shipping_fee) }}, Customer Fee: {{ $price(so.escrow_detail.order_income.buyer_paid_shipping_fee) }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="caption-small text-left" style="line-height: 140%;">
                    Customer notes : {{ so.data[0].message_to_seller || '-' }}
                  </div>
                  <v-divider class="mt-2" />
                  <v-row v-if="so.escrow_detail" no-gutters class="pt-2">
                    <v-col v-for="(oi, iOi) in so.escrow_detail.order_income.items" :key="'shopee-order-item-' + iSo + '-' + iOi" cols="12" class="col-sm-6 px-2 py-1 rounded" style="background-color: rgba(0, 0, 0, .04);">
                      <div class="d-flex fill-width align-center">
                        <div class="text-left d-flex flex-column fill-width" style="line-height: 140%!important;">
                          <div class="caption-small text-uppercase" style="line-height: 140%!important;">
                            SKU MAIN : {{ oi.item_sku }} {{ oi.model_sku ? (', SKU ITEM : ' + oi.model_sku) : '' }}
                          </div>
                          <div class="caption d-flex fill-width justify-space-between align-center" style="line-height: 140%!important;">
                            ({{ oi.quantity_purchased }}x) {{ oi.item_name }}
                            <strong>
                              {{ oi.model_name }}
                            </strong>
                          </div>
                        </div>
                        <v-spacer />
                        <div class="d-flex align-center justify-end pl-1 pr-2 py-1 text-right primary--text" style="min-width: 100px;">
                          <div v-if="parseInt(oi.original_price) === parseInt(oi.discounted_price)" class="caption-small font-weight-bold">
                            {{ $price(oi.original_price) }}
                          </div>
                          <div v-else class="text-right">
                            <div class="caption-small red--text text-decoration-line-through">
                              {{ $price(oi.original_price) }}
                            </div>
                            <div class="caption-small font-weight-bold">
                              {{ $price(oi.discounted_price) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="my-2" />
                  <div v-if="so.escrow_detail" class="caption-small d-flex flex-column fill-width justify-center align-end" style="line-height: 140%!important;">
                    <span>
                      Subtotal : {{ $price(so.escrow_detail.order_income.original_price) }}
                    </span>
                    <span class="red--text">
                      Discounted : -{{ $price(so.escrow_detail.order_income.seller_discount) }}
                    </span>
                    <span>
                      Total : {{ $price(so.escrow_detail.order_income.original_cost_of_goods_sold) }}
                    </span>
                    <span>
                      Customer Shipping Cost : {{ $price(so.escrow_detail.order_income.buyer_paid_shipping_fee) }}
                    </span>
                    <v-divider class="fill-width my-1" />
                    <strong class="pt-1">
                      Grand Total : {{ $price(so.escrow_detail.order_income.original_cost_of_goods_sold + so.escrow_detail.order_income.buyer_paid_shipping_fee) }}
                    </strong>
                  </div>
                </span>
              </v-card>
            </span>
          </div>
        </div>
        <div v-else-if="shopee.error && shopee.authLink" class="pa-4 text-center">
          <v-btn :href="shopee.authLink" target="_blank" depressed color="orange darken-2" @click="$store.commit('DIALOG_SHOPEE', false)">
            Authorize
          </v-btn>
        </div>
        <div v-else class="pa-4 text-center">
          {{ shopee.message || 'Something went wrong' }}
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions v-if="!stepConfirm" class="px-4">
        <v-btn
          color="red darken-1"
          text
          class="text-capitalize caption"
          @click="$store.commit('DIALOG_SHOPEE', false)"
        >
          Close
        </v-btn>
        <v-spacer />
        <!-- <v-btn
          v-if="dataSelected.length"
          color="red"
          dark
          small
          text
          class="text-capitalize caption mr-2"
          @click="dataSelected = []"
        >
          Clear Selected
        </v-btn> -->
        <v-btn
          v-if="orderStatus === 'READY_TO_SHIP'"
          color="blue darken-1"
          :disabled="dataSelected.length ? false : true"
          dark
          small
          outlined
          class="text-capitalize px-4 font-weight-bold caption-small"
          @click="stepConfirm = 1"
        >
          CONFIRM ({{ dataSelected.length }}) ORDER SELECTED
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else class="px-4">
        <v-btn
          color="red"
          dark
          small
          text
          class="text-capitalize caption mr-2"
          @click="stepConfirm = 0"
        >
          Back
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="dataSelected.length ? false : true"
          :loading="exportLoading"
          color="darken-1"
          dark
          small
          depressed
          class="text-capitalize blue px-4 font-weight-bold caption-small"
          @click="EXPORT_SHOPEE_TO_CRM()"
        >
          EXPORT ({{ dataSelected.length }}) ORDER TO CRM
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
let searchTimeout = null
export default {
  // props: {
  //   show: {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  data: () => ({
    stepConfirm: 0,
    dataSelected: [],
    dataProduct: [],
    dates: [],
    loading: false,
    menu: false,
    orderStatus: 'READY_TO_SHIP',
    orderSn: '',
    shopSelected: null,
    shopeeList: {
      authLink: '',
      data: []
    },
    shopee: {
      loading: false,
      error: false,
      authLink: null,
      message: '',
      data: []
    },
    exportLoading: false,
    nextTickDataSelected: true,
    transactionsList: []
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    ...mapState({
      show: state => state.dialogShopee
    })
  },
  watch: {
    show (v) {
      if (v) {
        this.shopSelected = null
        this.stepConfirm = 0
        this.shopee.error = false
        this.shopee.authLink = null
        this.shopee.data = []
        this.dataSelected = []
        this.orderSn = ''
        this.orderStatus = 'READY_TO_SHIP'
        this.dates = []
        this.LOAD_LIST()
        this.LOAD_PRODUCT()
      }
    },
    dates: function (v) {
      if (this.orderSn) {
        return false
      }
      if (!this.dates.length || this.dates.length === 2) {
        this.LOAD_DATA()
      }
    },
    shopSelected () {
      this.LOAD_DATA()
    },
    orderStatus (v) {
      if (this.orderSn) {
        return false
      }
      this.LOAD_DATA()
    },
    orderSn (v) {
      if (v) {
        this.dates = []
        this.orderStatus = 'ALL_STATUS'
      }
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.LOAD_DATA()
      }, 500)
    }
  },
  mounted () {
    this.TRX_GET()
  },
  methods: {
    SelectAll (isAllSelected) {
      this.dataSelected = []
      if (!isAllSelected) {
        const shopeeData = this.shopee.data || []
        for (let s = 0; s < shopeeData.length; s++) {
          this.dataSelect(Object.assign({}, shopeeData[s]))
        }
      }
    },
    waitNextTickDataSelected () {
      this.nextTickDataSelected = false
      setTimeout(() => {
        this.nextTickDataSelected = true
      }, 50)
    },
    IsProductSelected (skuCrm) {
      const p = this.dataProduct.find(r => (r.sku || '').toUpperCase() === (skuCrm || '').toUpperCase())
      /* eslint-disable-next-line */
      return p ? true : false
    },
    async EXPORT_SHOPEE_TO_CRM () {
      this.exportLoading = true
      let isErr = false
      const user = parseInt(this.$store.state.user.id) || 0
      const admin = parseInt(this.$store.state.user.uid) || 0
      const refidData = await this.SET_REFID(admin, user)
      const data = this.dataSelected || []
      const payloads = []
      for (let d = 0; d < data.length; d++) {
        const dd = Object.assign({}, data[d])
        const items = dd.escrow_detail ? (dd.escrow_detail.order_income.items || []) : []
        const carts = []
        for (let p = 0; p < items.length; p++) {
          const item = Object.assign({}, items[p])
          const product = this.GET_PRODUCT_ONE(item.sku_crm)
          const itemsInPackage = []
          if (!parseInt(product.id)) {
            isErr = true
          }
          const cart = {
            uid: 0, // sub account ID
            product: parseInt(product.id) || 0,
            product_package_mutation: 0, // ID PACKAGE FROM MUTATION
            is_merchandise: 0, // IS MERCHANDISE ?
            is_additional: 0, // IS ADDITIONAL ?
            trx: 0,
            user: 0, // creator / owner transaction
            sync: 0, // sync type for inventory sync
            seller: 0,
            buyer: 0,
            quantity: parseInt(item.quantity_purchased) || 1,
            // price: (parseInt(item.original_price) || 0) / (parseInt(item.quantity_purchased) || 1), // sell original price at marketplace
            price: parseInt(product.price) || 0, // force to crm price
            price_original_crm: parseInt(product.price) || 0,
            price_discount: (parseInt(item.discounted_price) || 0) / (parseInt(item.quantity_purchased) || 1),
            price_buy: parseInt(product.price_hpp) || 0, // hpp
            weight: 0,
            mpl_sn: '',
            status: 1
          }
          // IF PRODUCT IS PACKAGE
          if (parseInt(product.is_package)) {
            const packageItems = await this.GetPackageItems(product.id)
            for (let pkg = 0; pkg < packageItems.length; pkg++) {
              const pkgData = packageItems[pkg]
              itemsInPackage.push(Object.assign({}, {
                product_package_mutation: product.id, // temporary. actually this is for ID cart parent package
                product: pkgData.id,
                quantity: parseInt(pkgData.quantity) * parseInt(item.quantity_purchased),
                price: parseInt(pkgData.price) || 0,
                price_buy: parseInt(pkgData.price_hpp) || 0,
                price_original_crm: parseInt(pkgData.price) || 0,
                price_discount: 0
              }))
            }
          }
          carts.push(Object.assign((itemsInPackage.length ? { items: itemsInPackage } : {}), cart))
        }

        const address = dd.data[0].recipient_address.full_address
        // const address = dd.data[0].recipient_address.full_address + ', ' + (dd.data[0].recipient_address.district || '-') + ', ' + (dd.data[0].recipient_address.city || '-') + ', ' + (dd.data[0].recipient_address.state || '-') + ', ' + (dd.data[0].recipient_address.zipcode || '-')

        const customer = {
          name: dd.customer.name,
          username: dd.customer.username,
          phone: dd.customer.phone,
          area: parseInt(dd.customer.area) || 0,
          area_name: dd.customer.area_name,
          province: parseInt(dd.customer.province) || 0,
          city: parseInt(dd.customer.city) || 0,
          district: parseInt(dd.customer.district) || 0,
          address: dd.customer.address,
          joined_source: 'MPL/SHOP',
          joined_admin: admin,
          joined: this.$localDT(null, 'datedefault')
        }

        const payload = {
          customer,
          trx: {
            refid: refidData.reff + this.GET_REFID_INCREMENT(refidData.GETLASTID + d), // REFID
            trx_source: 'MPL/SHOP', // wa, web, mpl/name, etc
            trx_source_id: '',
            trx_source_name: '',
            user: user, // creator / owner transaction
            uid: admin, // user admin
            sync: 0, // sync inventory or not
            buyer: dd.buyer || 0,
            buyer_none_reason: 0, // IF buyer=0 >> 0: Default, 1: Hilang, 2: Rusak/Cacat
            seller: user,
            seller_vendor: 0, // Vendor ID IF seller=0 AND Only Buy

            // MY STOCK IN : Buy From Vendor
            // MY STOCK OUT : Sell To Customer

            dropship_name: '', // USED as VENDOR NAME if Sync (Stock Moving)
            dropship_phone: '', // USED as VENDOR PHONE if Sync (Stock Moving)
            dropship_notes: '', // USED as VENDOR NOTES if Sync (Stock Moving)

            shipping_address: address,
            shipping_courier: dd.data[0].shipping_carrier || dd.data[0].checkout_shipping_carrier,
            shipping_awb: dd.shipping_detail.tracking_number,
            shipping_cost_id: 0, // shipping_cost ID per KG

            shipping_weight: 0,
            shipping_total: dd.escrow_detail.buyer_paid_shipping_fee, // total shipping after kali /kg
            price_total: dd.escrow_detail.order_income.cost_of_goods_sold, // subtotal from cart
            // cost_of_goods_sold, original_cost_of_goods_sold,
            price_discount_label_price: 0, // 1: from Label Price ?, 2: from Custom, 0: Subtotal
            // price_discount: dd.escrow_detail.order_income.seller_discount, // discount global checkout
            price_discount: 0, // discount global checkout
            price_discount_treat: 0, // Promo treat customer from TOTAL (SUBTOTAL+ONGKIR-PROMO-DISCOUNT)
            payment_total: dd.escrow_detail.order_income.cost_of_goods_sold + dd.escrow_detail.order_income.buyer_paid_shipping_fee, // count all summary (subtotal - discount, + shipping)

            payment_receipt: '',
            payment_bank: '',
            payment_invoice: '',
            payment_src: '',
            payment_cod: dd.data[0].cod ? 1 : 0,

            platform: 'shopee', // bukalapak, shopee, tokopedia, lazada, lainnya
            notes: dd.data[0].message_to_seller,
            poin: 0,
            discount_package: 0, // IDR
            booking_code: '',
            updated: '',
            mpl_sn: dd.order_sn,
            mpl_detail: JSON.stringify(dd),
            created: this.$localDT(null, 'datedefault'),
            status: 2 // 0 = waiting payment : 1 = pending : 2 = processed : 3 = shipped : 4 = complete : 99 = canceled : 100 = deleted
          },
          carts
        }
        payloads.push(payload)
      }
      if (isErr) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please make sure all products are selected!' })
        this.exportLoading = false
        return false
      }
      this.$store.dispatch('SHOPEE_TRX_PROCESS', payloads)
        .then((res) => {
          this.exportLoading = false
          if (res && res.status) {
            this.$store.commit('SNACKBAR', { show: true, text: 'Successfully imported!' })
            this.$store.commit('DIALOG_SHOPEE', false)
            this.TRX_GET()
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Ops, something went wrong!' })
          }
        })

      // <div class="caption-small d-flex flex-column fill-width justify-center align-end" style="line-height: 140%!important;">
      //   <span>
      //     Subtotal : {{ $price(so.escrow_detail.order_income.original_price) }}
      //   </span>
      //   <span class="red--text">
      //     Discounted : -{{ $price(so.escrow_detail.order_income.seller_discount) }}
      //   </span>
      //   <span>
      //     Total : {{ $price(so.escrow_detail.order_income.original_cost_of_goods_sold) }}
      //   </span>
      //   <span>
      //     Customer Shipping Cost : {{ $price(so.escrow_detail.order_income.buyer_paid_shipping_fee) }}
      //   </span>
      //   <v-divider class="fill-width my-1" />
      //   <strong class="pt-1">
      //     Grand Total (CRM) : {{ $price(so.escrow_detail.order_income.original_cost_of_goods_sold + so.escrow_detail.order_income.buyer_paid_shipping_fee) }}
      //   </strong>
      // </div>
    },
    GET_PRODUCT_ONE (sku) {
      const product = this.dataProduct.find(r => r.sku === sku)
      return product || {}
    },
    async GetPackageItems (pid) {
      return await this.$store.dispatch('PRODUCT_PACKAGE_GET', pid)
        .then((res) => {
          if (res.status) {
            return res.data.data || []
          }
          return []
        })
    },
    isDataSelected (orderSN) {
      const findIndex = this.dataSelected.findIndex(r => r.order_sn === orderSN)
      return findIndex > -1
    },
    dataSelect (shopeeList) {
      const dataIndex = this.dataSelected.findIndex(r => r.order_sn === shopeeList.order_sn)
      if (dataIndex > -1) {
        this.dataSelected.splice(dataIndex, 1)
      } else {
        // name: dd.data[0].recipient_address.name,
        // username: 'shopee-' + dd.data[0].buyer_username,
        // phone: this.$reformatPhone(dd.data[0].recipient_address.phone),
        // joined_source: 'MPL/SHOP',
        // joined_admin: admin,
        // joined: this.$localDT(null, 'datedefault')
        const newData = Object.assign({
          customer: {
            role: 'customer',
            id: 0,
            name: shopeeList.data[0].recipient_address.name,
            username: 'shopee-' + shopeeList.data[0].buyer_username,
            phone: this.$reformatPhone(shopeeList.data[0].recipient_address.phone),
            area: 0,
            area_name: (shopeeList.data[0].recipient_address.district || '-') + ', ' + (shopeeList.data[0].recipient_address.city || '-').replace('Kab.', 'Kabupaten'),
            province: 0,
            city: 0,
            district: 0,
            address: shopeeList.data[0].recipient_address.full_address || ''
          }
        }, shopeeList)
        newData.buyer = 0
        const items = newData.escrow_detail.order_income.items || []
        for (let i = 0; i < items.length; i++) {
          const item = Object.assign({}, items[i])
          item.sku_crm = item.model_sku || item.item_sku || 'no-sku'
          newData.escrow_detail.order_income.items[i] = item
        }
        this.dataSelected.push(newData)
      }
      this.waitNextTickDataSelected()
    },
    OpenAuthorizeLink (link) {
      const a = document.createElement('a')
      a.setAttribute('target', '_blank')
      a.setAttribute('href', this.shopeeList.authLink)
      a.click()
      this.$store.commit('DIALOG_SHOPEE', false)
    },
    LOAD_LIST () {
      this.shopeeList.authLink = ''
      this.shopeeList.data = []
      this.$store.dispatch('SHOPEE_GET')
        .then((res) => {
          if (res && res.data && res.data.data && res.data.data.response) {
            this.shopeeList.authLink = res.data.data.authLink || ''
            this.shopeeList.data = res.data.data.list || []
          }
        })
    },
    LOAD_DATA () {
      const shopID = this.shopSelected ? this.shopSelected.shop_id : ''
      if (!parseInt(shopID)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'No shop selected!' })
        return false
      }
      const getDates = this.GetDates()
      let from = Math.floor((new Date(getDates.from)) / 1000)
      let to = Math.floor((new Date(getDates.to)) / 1000)
      let orderStatus = (this.orderStatus === 'ALL_STATUS' || !this.orderStatus) ? '' : this.orderStatus
      const orderSn = this.orderSn || ''
      if (orderSn) {
        orderStatus = ''
        from = ''
        to = ''
      } else if (!from || !to) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select date ranges!' })
        return false
      }

      this.shopee.loading = true
      this.shopee.error = false
      this.shopee.authLink = null
      this.shopee.data = []
      this.$store.dispatch('SHOPEE_ORDER', `?shop_id=${shopID}&from=${from}&to=${to}&order_status=${orderStatus}&order_sn=${orderSn}`)
        .then((res) => {
          this.shopee.error = true
          this.shopee.message = ''
          if (res && res.status && !res.data.data.error) {
            this.shopee.error = false
            this.shopee.authLink = null
            const _data = res.data.data.data.filter((order) => order.order_status !== 'CANCELLED' && order.order_status !== 'PROCESSED' && order.order_status !== 'UNPAID')
            this.shopee.data = _data || []
          } else if (res && res.data && res.data.data && res.data.data.error === 'error_auth') {
            this.shopee.authLink = res.data.data.data || null
            this.shopee.message = 'Unauthorized'
            // this.$store.commit('SNACKBAR', { show: true, text: res.data.data.message })
          } else if (res && res.data && res.data.data && res.data.data.message) {
            this.$store.commit('SNACKBAR', { show: true, text: res.data.data.message || 'Something went wrong' })
            this.shopee.message = res.data.data.message
          }
          this.shopee.loading = false
        })
    },
    LOAD_DATA_DETAIL (sn) {
      // const from = ''
      // const to = ''
      if (!sn) {
        return false
      }
      this.$store.dispatch('SHOPEE_GET', '?sn=' + sn)
        .then((res) => {
          this.shopee.error = true
          if (res && res.status && !res.data.data.error) {
            this.shopee.error = false
            this.shopee.data = []
          } else if (res && res.data && res.data.data && res.data.data.error === 'error_auth') {
            this.shopee.authLink = res.data.data.data || null
            // this.$store.commit('SNACKBAR', { show: true, text: res.data.data.message })
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
    },
    LOAD_PRODUCT () {
      this.$store.dispatch('PRODUCT_GET', '?page=1&limit=10000').then((res) => {
        if (res.status) {
          this.dataProduct = res.data.data.data
        } else {
          this.dataProduct = []
        }
      })
    },
    GetDates () {
      const fromTo = this.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return {
              from: fromTo[0],
              to: fromTo[1]
            }
          } else {
            return {
              from: fromTo[1],
              to: fromTo[0]
            }
          }
        }
      }
      const todayStart = new Date()
      todayStart.setHours(0)
      todayStart.setMinutes(0)
      todayStart.setSeconds(0)
      // const todayEnd = new Date()
      // todayEnd.setHours(23)
      // todayEnd.setMinutes(59)
      // todayEnd.setSeconds(59)
      // const oneday = (24 * 60 * 60)
      return {
        from: Math.floor((todayStart.getTime())),
        to: Math.floor((new Date()).getTime())
      }
    },
    async SET_REFID (admin, user) {
      let GETLASTID = 0
      const reff = 'MPL/SHOP/' + (this.GEN_REFID_DATE() + '/')
      const X = await this.LAST_REFID_GET('/MPL-SHOP/' + this.$localDT(new Date(), 'datedefault'))
      if (X) {
        if (X.status) {
          GETLASTID = parseInt(X.data.data.total) || 0
        }
      }
      const result = reff + this.GET_REFID_INCREMENT(GETLASTID)
      return {
        result,
        reff,
        GETLASTID
      }
    },
    async LAST_REFID_GET (ref) {
      return await this.$store.dispatch('LAST_REFID_GET', ref)
        .then(res => res)
        .catch(err => err)
    },
    GET_REFID_INCREMENT (n) {
      n = n + 1
      if (n < 10) {
        return '000' + n
      } else if (n < 100) {
        return '00' + n
      } else if (n < 1000) {
        return '0' + n
      } else {
        return n
      }
    },
    GEN_REFID_DATE () {
      const date = this.$localDT(null, 'datedefault')
      const [year, month, day] = date.split('-')
      const y = year ? year.substr(2, 2) : 'XX'
      return `${day}${month}${y}`
    },
    TRX_GET () {
      this.$store.dispatch('TRX_GET', null).then((res) => {
        if (res.status) {
          this.transactionsList = res.data.data.data
        }
      })
    }
  }
}
</script>

<style lang="scss">
.shopee-data-list {
  transition: background-color .2s ease;
  cursor: pointer;
  &:hover {
    background-color: rgba(154, 138, 248, 0.05);
  }
  &.selected {
    background-color: rgba(154, 138, 248, 0.15);
  }
}
</style>
